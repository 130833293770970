@import "bootstrap_custom";

img {
  max-width: 100%;
}

html, body {
  background-color: #F1F1F1;
  font-family: "Lato";
  color: #333;
  font-size: 13px;
}
header {
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
  color: #333;

  background: #202020;
  background: radial-gradient(circle, #999691 0%, #333 100%);
}
body.design {
  min-width: 1200px;
}
body.splash header {
  position: absolute;
  color: #fff;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%);
  padding-top: 30px;
}

header .tier1, header .tier2 {
  max-width: 1200px;
  margin: 0 auto;
}

header hr {
  max-width: 1200px;
  margin: 10px auto;
  border: none;
  height: 1px;

  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.025) 0%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0.025) 100%);
}

header a, header a:hover {
  color: inherit;
  text-decoration: none;
}
header .tier1 {
  text-align: right;
  letter-spacing: 1px;
}
header .tier1 .logo {
  float: left;
}
header .tier1 nav {
  position: relative;
  display: inline-block;
  font-size: 11px;
  color: #fff;
}
header .tier1 nav .nav-item {
  color: inherit;
  margin: 0 8px;
}
header .tier1 nav .divider {
  display: inline-block;
  margin: 0 8px;
  content: "&bull;";
}
header .tier2 {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 15px;
}
header .tier2 .nav-item {
  display: inline-block;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 300;
  font-size: 13px;
  padding: 0 20px;
}
header .tier2 .nav-item > .label {
  display: inline-block;
  margin-bottom: 10px;
}
header .tier2 .nav-item > span.label {
  cursor: default;
}

header .nav-item {
  text-shadow: 0 1px 2px rgba(0,0,0,.75);
}
header .nav-item .nav-dropdown-items {
  text-shadow: none;
}

header .tier2 a.nav-item i {
  text-transform: lowercase;
  font-family: "Times New Roman", serif;
  font-weight: lighter;
  opacity: .5;
}

main {
  margin: 50px 0;
  min-height: 300px;
}

body.splash main {
  margin: 0;
}

section.formbox {
  margin: 50px 0;
}
section.formbox .inner {
  padding: 50px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);
}
h1 {
  margin: 50px 0;
  font-size: 36px;
  font-weight: 100;
  text-transform: uppercase;
  color: #000;
}
h1 .sub {
  font-family: "Lato", sans-serif;
  font-style: normal;
  display: block;
  font-size: 11px;
  font-weight: 300;
  color: #777;
  margin-top: 12px;
  letter-spacing: 2px;
}
h1 .sub .vr {
  margin: 0 10px;
}

h2 {
  font-size: 24px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
p {
  line-height: 24px;
  font-size: 14px;
  color: #666;
}


:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaa;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #aaa;
}

.note {
  opacity: .5;
  font-size: 11px;
  line-height: 28px;
}

.centerizer {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
}

.fade.show-on-load, .fade.show-on-load-sequential {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}


footer {
  color: #777;
  font-size: 13px;
  line-height: 22px;
}
footer .inner {
  margin-top: 100px;
  padding: 100px 0;
  border-top: 1px solid rgba(0,0,0,.05);
}
footer nav {
  margin-bottom: 20px;
}
footer a, footer a:hover {
  color: inherit;
  text-decoration: none;
}
footer strong {
  text-transform: uppercase;
}

body.splash footer {

}

.copyright {
  opacity: .5;
}
.subtle {
  opacity: .5;
}
.semi-subtle {
  opacity: .75;
}


/**
 * Bootstrap customizations
 */
.modal * {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-control {
  border-radius: 0;
  border: none;
  background-color: rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
}

.form-control.white {
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
}
.form-control-danger {
  outline: 1px solid red;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(0,0,0,.25);
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
  font-family: "Lato", sans-serif;
  letter-spacing: 2px;
  border: 0;
}
.btn-primary {
  background-color: #000;
}
.btn-primary:hover {
  background-color: #55524a;
}

.btn-secondary {
  background-color: #aaa;
}
.btn-secondary:hover {
  background-color: #666;
}
.dropdown-menu {
  border-radius: 0;
  box-shadow: 2px 5px 2px rgba(0,0,0,.2);
}
.dropdown-menu.dropdown-signin-form {
  width: 240px;
  padding: 20px;

  a {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    letter-spacing: .5px;
    display: block;
    color: #888;
    font-size: 14px;

    &:hover {
      color: #000;
    }
  }
}