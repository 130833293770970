//--------------------------
// Inputs & buttons
//--------------------------
.form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &.form-control-no-border {
        border: 0;
        box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
    }
}
.input-group-btn {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.btn {
    border-radius: 0;
}
label.btn {
    margin: 0 !important;
}
.input-group {
    .input-group-btn {

        .btn {
            border: 1px solid #ced4da;
        }

        &:first-child {
            .btn {
                border-right: none;
            }
        }
        &:last-child {
            .btn {
                border-left: none;
            }
        }
    }
}

//--------------------------
// Text & Typography
//--------------------------
.text-hover-danger:hover {
    color: #f00;
}
.badge-default {
    background-color: #eee;
}

//--------------------------
// Card
//--------------------------
.card {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

    .card-header {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        h2 {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.25em;
            font-weight: 300;
            margin: 0;
            color: #555;
        }
    }
}

//--------------------------
// Nav
//--------------------------
h2+.nav {
    margin-top: 20px;
}
.nav-pills .nav-link {
    border-radius: 0;
}

//--------------------------
// Modal
//--------------------------
@media (min-width: 768px) {
    .modal-xl {
        width: 90%;
        max-width:1300px;
    }
    .modal-carousel {
        max-width: 1000px;
    }
}

//--------------------------
// Override col width for print
//--------------------------
@media print{
    .col-print-1 {width:8%;  float:left;}
    .col-print-2 {width:16%; float:left;}
    .col-print-3 {width:25%; float:left;}
    .col-print-4 {width:33%; float:left;}
    .col-print-5 {width:42%; float:left;}
    .col-print-6 {width:50%; float:left;}
    .col-print-7 {width:58%; float:left;}
    .col-print-8 {width:66%; float:left;}
    .col-print-9 {width:75%; float:left;}
    .col-print-10{width:83%; float:left;}
    .col-print-11{width:92%; float:left;}
    .col-print-12{width:100%; float:left;}
}
